import { debug } from "./debug";

class Strings {

  constructor(){}

  /**
   * INITIALISATION
   * @returns 
   */
  init = () => {
    if(debug.DEBUG) console.log('helpers/strings.js');
  }

  camelCase = (string) => {
    return string
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
  }
  kebabCase = (string) => {
    return string
      .replace(/([a-z])([A-Z])/g, '$1-$2') // Convert camelCase to kebab-case
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Convert non-alphanumeric characters to hyphens
      .replace(/^-+|-+$/g, ''); // Remove hyphens from both ends
  };
  snakeCase = (string) => {
    return string
      .replace(/([a-z])([A-Z])/g, '$1_$2') // Convert camelCase to snake_case
      .replace(/[^a-zA-Z0-9]+/g, '_')      // Convert non-alphanumeric characters to underscores
      .replace(/^_+|_+$/g, '')             // Remove underscores from both ends
      .toLowerCase();                      // Convert to lowercase
  };
  
  quoteAttr = (s, preserveCR) => {
    preserveCR = preserveCR ? '&#13;' : '\n';
    return ('' + s) /* Forces the conversion to string. */
        .replace(/&/g, '&amp;') /* This MUST be the 1st replacement. */
        .replace(/'/g, '&apos;') /* The 4 other predefined entities, required. */
        .replace(/"/g, '&quot;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        /*
        You may add other replacements here for HTML only 
        (but it's not necessary).
        Or for XML, only if the named entities are defined in its DTD.
        */ 
        .replace(/\r\n/g, preserveCR) /* Must be before the next replacement. */
        .replace(/[\r\n]/g, preserveCR);
  }
  uppercase = (string) => {
    return string.toUpperCase();
  }
  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  phone = (string) => {
    return string.replace(/ /g, '').replace(/\D+/g, '').replace(/(\d{0,2}(\.\d{1,2})?)/g, "$1 ").trim().substr(0, 14);
  }
  number = (string) => {
    return string.replace(/ /g, '').replace(/\D+/g, '');
  }
  isNumeric = (string) => {
    return !isNaN(parseFloat(string)) && isFinite(string);
  }
}
export const strings = new Strings(); 
document.addEventListener("DOMContentLoaded", function() {
  strings.init();
});