
import tailwindConfigScreens from "../../../tailwind.screens.config.js";
import { debug } from "./debug";
import { object } from "./object.js";
import { throttle } from "throttle-debounce";
import { utils } from "./utils.js";

/*------------------------------------*\
  #RESPONSIVE

  Class gestion des responsive
\*------------------------------------*/
class Responsive {
  _currentScreen;
  _screens;
  constructor(){
    this._screens = Object.entries(tailwindConfigScreens);
  }

  /*------------------------------------*\
    #INITIALISATION
  \*------------------------------------*/
  init = () => {
    this.responsiveHandler();
    addEventListener("resize", this.responsiveHandler);
    if(debug.DEBUG) console.log('helpers/Responsive.js');
  }

  responsiveHandler = throttle(100, () => {
    if (this.screen === this._currentScreen) return false;
    this._currentScreen = this.screen;
    setTimeout(()=> {
      utils.dispatchEvent('RESPONSIVE_HAS_CHANGED', {screen: this.screen});
      if(debug.DEBUG) console.log('RESPONSIVE_HAS_CHANGED', {screen: this.screen});
    }, 100);
  });

  is = (size) => {
    return this.screens.some(([screen, value]) => screen === size && parseInt(value) <= window.innerWidth);
  }
  /*------------------------------------*\
    #SCREEN

    get current screen (le plus grand)
  \*------------------------------------*/
  get screen () {
    if (!this.screens.length) return 'mobile';
    return this.screens.slice(-1)[0][0];
  }
  /*------------------------------------*\
    #SCREENS

    get current screens - tout les screens 
    actifs
  \*------------------------------------*/
  get screens () {
    return this._screens.filter(([screen, value]) => parseInt(value) <= window.innerWidth);
  }
  
}
export const responsive = new Responsive(); 
document.addEventListener("DOMContentLoaded", function() {
  responsive.init();
});