import { object } from "../helpers/object";
import { debug } from "../helpers/debug";
import { strings } from '../helpers/strings';

/*------------------------------------*\
  #GTM

  Gestion du traking

  [data-js-gtm-tracker]
\*------------------------------------*/
class Gtm {


  constructor(module){
    this.MODULE = module;
    this.DOM_MODULE = object.toArray(document.querySelectorAll(this.MODULE));
  }
  /**
   * INITIALISATION
   * @returns 
   */
  init = () => {
    if (!this.DOM_MODULE.length) return;
    // DEFAULT EVENT
    document.addEventListener('click', this.clickHandler);
    if(debug.DEBUG) console.log('components/gtm.js');
  };

  /**
   * CLICK HANDLER
   * 
   * Gestionnaire des event de la barre de navigation
   * @param {event} event 
   * @returns 
   */
  clickHandler = (event) => {
    if (event.target.matches('[data-js-gtm-tracker]')) {
      this.getDatas(event.target)
    }
  }
  /**
   * GET DATAS
   * 
   * return un object pour le tracking GTM
   * @param {Node} element 
   */
  getDatas = (element) => {
    const datasGtm = Array.from(element.attributes)
      .filter(attr => /^data-js-gtm/.test(attr.name) && attr.value !== '')
      .reduce((acc, attr) => {
        const nameClean = strings.camelCase(attr.name.replace('data-js-gtm-', ''));
        if (nameClean === 'tracker') return acc;
        acc[nameClean] = attr.value;
        return acc;
      }, {});
  
    this.track(datasGtm);
  };
  /**
   * TRACK
   * 
   * tracking d'un event format de l'objet de type GTM
   * @param {object} params 
   */
  track = function (params) {
    if (typeof dataLayer != 'undefined') {
        dataLayer.push(params);
        if(debug.DEBUG) console.log('GTM:', params);
    } else {
        console.error('tracker GTM undefined');
    }
  }
}
export const gtm = new Gtm('[data-js-gtm-tracker]'); 