import { debug } from "./debug";

class Tpl {

    constructor(){
        this.cache = {};
    }

    /**
     * INITIALISATION
     * @returns 
     */
    init = () => {
        if(debug.DEBUG) console.log('helpers/tpl.js');
    }
    /**
     * Retrieves and processes an HTML template with the provided data.
     *
     * @param {Object} data - The data to be injected into the template.
     * @param {string} templateId - The ID of the HTML template element.
     * @returns {string} - The processed HTML template with data injected.
     */
    get = (data, templateId) => {
        let templateHTML = this.getCache(templateId);
        
        if (!templateHTML) {
            const tpl = document.getElementById(templateId);
            if (!tpl) {
                if (debug.DEBUG) console.error(`tpl id "${templateId}" not found`);
                return;
            }
            templateHTML = tpl.innerHTML;
            this.setCache(templateId, templateHTML);
        }
      
        return templateHTML.replace(/{{([^}]*)}}/g, (search, result) => data[result] || '');
    };
      
    /**
     * GET CACHE
     * @param {string} templateId 
     */
    getCache = (templateId) => {
        return this.cache[templateId];
    };
    /**
     * SET CACHE
     * @param {string} templateId 
     * @param {HTMLElement} html 
     */
    setCache = (templateId, html) => {
        this.cache[templateId] = html;
    };

  }
export const tpl = new Tpl(); 
document.addEventListener("DOMContentLoaded", function() {
  tpl.init();
});