module.exports = {
  'sm': '640px',
  // => @media (min-width: 640px) { ... }

  'md': '768px',
  // => @media (min-width: 768px) { ... }

  'md-max': {'max': '767px'},
  // => @media (max-width: 767px) { ... }

  'lg': '1024px',
  // => @media (min-width: 1024px) { ... }

  'lg-max': {'max': '1023px'},
  // => @media (max-width: 1024px) { ... }

  'xl': '1180px',
  // => @media (min-width: 1280px) { ... }

  'xl-max': {'max': '1180px'},

  'storelocator': {'raw': 'screen and (min-width: 768px) and (max-height: 860px)'},
  // => @media (max-width: 1024px) { ... }

  // '2xl': '1536px',
  // => @media (min-width: 1536px) { ... }
}