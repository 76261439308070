import { object } from "../helpers/object";
import { debug } from "../helpers/debug";
import { utils } from "../helpers/utils";
import { mask } from "../components/mask";
import { tpl } from "../helpers/tpl";



/*------------------------------------*\
  #DIALOG

  Gestion des dialogs
\*------------------------------------*/
class Dialog {


  constructor(module){
    this.MODULE = module;
    this.DOM_MODULE = object.toArray(document.querySelectorAll(this.MODULE));
    this.triggerElement = null;
    this.targetContentSaveID = null;
    this.targetContentSaveContent = null;
  }
  /**
   * INITIALISATION
   * @returns 
   */
  init = () => {
    if (!this.DOM_MODULE.length) return;
    // DEFAULT EVENT
    document.addEventListener('click', this.clickHandler);
    // DEFAULT EVENT KEYUP
    document.addEventListener('keyup', this.keyHandler);

    if(debug.DEBUG) console.log('components/dialog.js');
  };

  /**
   * CLICK HANDLER
   * 
   * Gestionnaire des event de la barre de navigation
   * @param {event} event 
   * @returns 
   */
  clickHandler = (event) => {
    if (event.target.matches('[data-js-dialog-open]')) {
      // open dialog
      const dialogId = event.target.getAttribute('data-js-dialog-open');
      const contentId = event.target.getAttribute('data-js-dialog-content') ?? null;
      this.open(dialogId, contentId);
      this.triggerElement = event.target;
    } else if (event.target.matches('[data-js-trigger="dialog-close"]') ||
               event.target.matches('[data-js-trigger="mask"]')) {
      // close dialog
      this.close();
    }
  }
  /**
   * CLOSE ON ESC KEY
   * 
   * Fermeture des sub-nav au clic sur la touche echap
   * @param {event} event 
   */
  keyHandler = (event) => {
    const key = event.key || event.keyCode;
    const isEscapeKey = utils.isEscapKey(key);
    const isTabKey = utils.isTabKey(key);
    const isModalOpen = event.target.closest('[aria-modal="true"][aria-hidden="false"]');

    if (isEscapeKey) {
      this.close();
    } else if (isTabKey && !isModalOpen) {
      this.focusIn();
      event.preventDefault();
    }
  }
  targetContentReBuild = () => {
    if(!this.targetContentSaveContent && !this.targetContentSaveID) return;
    const targetDialog = document.querySelector(`.c-dialog--${this.targetContentSaveID}`);
    const targetContent = document.querySelector(`#${this.targetContentSaveID}`);
    targetContent.innerHTML = this.targetContentSaveContent;
    if (!targetDialog) return;
    targetDialog.classList.remove(`c-dialog--${this.targetContentSaveID}`);
  }
  /**
   * Opens a dialog with the specified dialogId and optionally updates its content with the specified contentId.
   * 
   * @param {string} dialogId - The ID of the dialog to open.
   * @param {string} [contentId] - The ID of the content to load into the dialog. If not provided, the dialog content is not updated.
   */
  open = (dialogId, contentId) => {
    this.close();
    this.triggerElement = null;
  
    const target = document.querySelector(`[data-js-dialog-id="${dialogId}"]`);
    if (!target) return;
  
    target.dispatchEvent(new Event('on.open'));
    const wrapper = this.getWrapper(target);
  
    if (contentId) {
      const targetContent = document.querySelector(`#${contentId}`)?.cloneNode(true);
      if (!targetContent) return;
      
      this.targetContentSaveContent = targetContent.innerHTML;
      this.targetContentSaveID = contentId;
      document.querySelector(`#${contentId}`).innerHTML = '';

      const targetTitle = targetContent.querySelector(`[data-js-dialog-title]`);
      if (targetTitle) {
        target.querySelector(`[data-js-dialog-title]`).innerText = targetTitle.textContent;
        targetTitle.remove();
      }
  
      const trigger = targetContent.getAttribute('data-js-dialog-trigger');
      if (trigger) {
        const triggerElement = target.querySelector(`button[type="submit"]`);
        if (triggerElement) triggerElement.setAttribute('data-js-trigger', trigger);
      }
      
      const dialogContent = target.querySelector(`[data-js-dialog-content]`);
      dialogContent.innerHTML = '';
      dialogContent.appendChild(targetContent);
      target.classList.add(`c-dialog--${contentId}`);
    }
  
    target.setAttribute('aria-hidden', 'false');
    wrapper.setAttribute('data-js-dialog-open', 'true');
    mask.display = true;
  }

  error = (message) => {
    const data = {
      error: message
    }
    const popinErrorID = 'popin-error';
    const popinError = dialog.get(popinErrorID);
    popinError.innerHTML = tpl.get(data, popinErrorID)
    dialog.open(popinErrorID)
  }

  lock = (id) => {
    const dialog = this.get(id);
    if (!dialog) return;
    dialog.setAttribute('data-js-dialog-lock', true);
  }

  /**
   * Closes a dialog with the specified ID or the currently opened dialog if no ID is provided.
   *
   * @param {string} [id] - The ID of the dialog to close. If not provided, the currently opened dialog will be closed.
   */
  close = (id) => {

    this.targetContentReBuild();

    const openedDialog = id !== undefined ? this.get(id) : this.getOpenedDialog();
    if (!openedDialog) return;
    
    if (openedDialog.getAttribute('data-js-dialog-lock') === 'true') {
      mask.lock = true;
      return;
    } else {
      mask.lock = false;
    }

    const dialogId = openedDialog.getAttribute('data-js-dialog-id');
    const button = this.triggerElement ?? document.querySelector(`[data-js-dialog-open="${dialogId}"]`);
    if (button) button.focus();
  
    const state = id !== undefined && this.getOpenedDialogID() && this.getOpenedDialogID() !== id;
    const wrapper = this.getWrapper(openedDialog);
  
    mask.display = state;
    wrapper.setAttribute('data-js-dialog-open', state);
    openedDialog.removeAttribute('data-js-dialog-lock');
    openedDialog.setAttribute('aria-hidden', true);
  }

  /**
   * Focuses on the first focusable child element within the currently opened dialog.
   * 
   * This method retrieves the currently opened dialog and attempts to find the first
   * focusable child element within it. If such an element is found, it sets the focus
   * on that element.
   * 
   * @returns {void}
   */
  focusIn = () => {
    const openedDialog = this.getOpenedDialog();
    if (openedDialog) {
      const firstFocusableChild = openedDialog.querySelector(this.focusableSelectors().join(','));
      if (firstFocusableChild) {
        firstFocusableChild.focus();
      }
    }
  }
  

  focusableSelectors = () => {
    return [
      'a[href]:not([tabindex^="-"])',
      'area[href]:not([tabindex^="-"])',
      'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
      'input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked',
      'select:not([disabled]):not([tabindex^="-"])',
      'textarea:not([disabled]):not([tabindex^="-"])',
      'button:not([disabled]):not([tabindex^="-"])',
      'iframe:not([tabindex^="-"])',
      'audio[controls]:not([tabindex^="-"])',
      'video[controls]:not([tabindex^="-"])',
      '[contenteditable]:not([tabindex^="-"])',
      '[tabindex]:not([tabindex^="-"])',
    ];

  }
  template = (id, data) => {
    const dialog = this.get(id);
    if (!dialog) return;
    dialog.innerHTML = tpl.get(data, id)
  }

  getOpenedDialog = () => {
    return document.querySelector('[data-js-dialog-id][aria-hidden="false"]') ?? false;
  }

  getOpenedDialogID = () => {
    const dialog = document.querySelector('[data-js-dialog-id][aria-hidden="false"]') ?? false;
    if (dialog) {
      return dialog.getAttribute('data-js-dialog-id');
    }
    return false;
  }

  get = (id, target) => {
    if (target === undefined) target = '';
    return document.querySelector(`[data-js-dialog-id="${id}"] ${target}`);
  }

  getAll = (id, target) => {
    if (target === undefined) target = '';
    return document.querySelectorAll(`[data-js-dialog-id="${id}"] ${target}`);
  }

  getWrapper = (elem) => {
    return elem.closest('[data-js-dialog]');
  }
}
export const dialog = new Dialog('[data-js-dialog]'); 