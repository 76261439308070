import { debug } from "./debug";
import { gtm } from "../components/gtm";
import $ from "jquery";
import { dialog } from "../components/dialog";

/*------------------------------------*\
  #SERVICES

  Class gestion des services
\*------------------------------------*/
class Services {
  services;
  constructor(){
    this.services = {
      apiyoutube: { url: '/services/api_youtube', method: 'get' },
      // wihslist
      wishlistdetail: { url: '/services/wishlist/detail', method: 'get' },
      wishlistdetailtechfile: { url: '/services/wishlist/detail_technical_file', method: 'get'},
      wishlistexport: { url: '/services/wishlist/export', method: 'post' },
      wishlistimport: { url: '/services/wishlist/to_json', method: 'post', contentType: false, processData: false},
      wishlistpopinaddto: { url: '/services/wishlist/add', method: 'get' },
      wishlistprint: { url: '/services/wishlist/print', method: 'post' },
      wishlistproget: { url: '/services/wishlist/pro_get', method: 'get' },
      wishlistprosave: { url: '/services/wishlist/pro_save', method: 'post' },
      wishlistproupdate: { url: '/services/wishlist/pro_update', method: 'post' },
      wishlisttechfile: { url: '/services/wishlist/technical_file', method: 'get' },
      // wishlist - documentation
      documentationproget: { url: '/services/documentation/pro_get', method: 'post' },
      documentationprosave: { url: '/services/documentation/pro_save', method: 'post' },
      // product
      pdfexport: { url: '/services/pdf/print', method: 'post' },
      produitblockpromo: { url: '/services/json/promoblock', method: 'get'},
      // comparator
      comparator: { url: '/services/comparateur', method: 'get' },
      // search
      suggest: { url: '/services/suggest', method: 'get' },
      scangencode: { url: '/services/scangencode/detail', method: 'get' },
      // form
      prefillingContactCommercial: { url: '/services/formulaires/contact-commercial/get', method: 'post' },
      prefillingDocumentPapier: { url: '/services/formulaires/document_papier/get', method: 'post' },
      prefillingPreinscriptionFormations: { url: '/services/formulaires/preinscription-formations/get', method: 'post' },
      prefillingShowroomDirectionRegionales: { url: '/services/formulaires/showroom-direction-regionales/get', method: 'post' },
      prefillingpartagerParEmail: { url: '/services/formulaires/partager-par-email/get', method: 'post' },
      // crossknowledge
      crossknowledge: { url: '/services/crosskkowledge/check', method: 'post'},
      crossknowledgeForm: { url: '/services/formulaires/crossknowledge/post', method: 'post'},
      termsofuse: {url: '/services/oauth2/tou/get', method: 'post'},
      oauth2IsNew: {url: '/services/oauth2/isnew', method: 'post'},
      updatetermsofuse: {url: '/services/oauth2/tou/update', method: 'post'}
    }
  }

  /*------------------------------------*\
    #INITIALISATION
  \*------------------------------------*/
  init = () => {
    if(debug.DEBUG) console.log('helpers/Services.js');
  }
  onAlways = () => {
  };
  onFail = (jqXHR, textStatus, errorThrown) => {
      //NOT FOUND OR METHOD NOT ALLOWED
      let statusCode = jqXHR.status;
      let httpResponseCodeValid = [200,301,400];
      // if (!/200|301|400|0/.test(statusCode)) { //Feature #53538
      if (httpResponseCodeValid.indexOf(statusCode)) {
          gtm.track({'event': `erreur service : ${textStatus}`});
          dialog.close('popin-loading');
      }
  };
  ajax = (options) => {
    if (options.data !== undefined) {
      if (options.data.fail === undefined) {
          return $.ajax(options).always().fail(services.onFail);
      } else {
          return $.ajax(options).always();
      }
    } else {
        return $.ajax(options).always();
    }
  }
  use = async (service, data, loader) => {
    const serviceConfig = this.services[service];
    if (!serviceConfig) {
      return console.error(`API Service ${service} not defined`);
    }
    loader = false;
    if(debug.DEBUG) console.log('service', serviceConfig, data);
    const options = {
      url: serviceConfig.url,
      method: serviceConfig.method,
      contentType: serviceConfig.contentType,
      processData: serviceConfig.processData,
      data: data
    };
  
    return this.ajax(options, loader);
  }
}
export const services = new Services(); 
document.addEventListener("DOMContentLoaded", function() {
  services.init();
});