import { object } from "../helpers/object";

/*------------------------------------*\
  #DEBUG

  Class gestion des element easy-toggle
\*------------------------------------*/
class Debug {

  constructor(module){
    this.DEBUG = window.DEBUG;
  }
  /**
   * INITIALISATION
   * @returns 
   */
  init = () => {
    this.DEBUG = this.force();
    if (!this.DEBUG) return;
    console.log('helpers/debug.js');
  }
  force = () => {
    if (window.DEBUG) return window.DEBUG;
    const urlParams = new URLSearchParams(window.location.search);
    return window.DEBUG = urlParams.has('DEBUG') && urlParams.get('DEBUG') === window.atob('cGl4aW5l');
  }
  log = (...args) => {
    if (this.DEBUG) console.log.apply(this, args);
  }

  get debug () { this.DEBUG; }
  set debug (_debug) { this.DEBUG = _debug; }
}
export const debug = new Debug(); 
document.addEventListener("DOMContentLoaded", function() {
  debug.init();
});