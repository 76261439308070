import { debug } from "./debug";

/*------------------------------------*\
  #UTILS

  Class gestion des utils
\*------------------------------------*/
class Utils {
  constructor(){
    let scrollLastPosition = 0;
  }

  /*------------------------------------*\
    #INITIALISATION
  \*------------------------------------*/
  init = () => {
    if(debug.DEBUG) console.log('helpers/Utils.js');
  }
  isEscapKey = (key) => {
    if (key === 'Escape' || key === 'Esc' || key === 27) return true;
    return false;
  }
  isEnterKey = (key) => {
    if (key === 'Enter' || key === 'Enter' || key === 13) return true;
    return false;
  }
  isSpaceKey = (key) => {
    if (key === 'Space' || key === ' ' || key === 32) return true;
    return false;
  }
  isTabKey = (key) => {
    if (key === 'Tab' || key === 9) return true;
    return false;
  }
  isUpKey = (key) => {
    if (key === 'ArrowUp' || key === 38) return true;
    return false;
  }
  isDownKey = (key) => {
    if (key === 'ArrowDown' || key === 40) return true;
    return false;
  }
  isLeftKey = (key) => {
    if (key === 'ArrowLeft' || key === 37) return true;
    return false;
  }
  isRightKey = (key) => {
    if (key === 'ArrowRight' || key === 39) return true;
    return false;
  }
  dataBool = (data) => {
    return data === 'true';
  }
  dispatchEvent = (eventName, params) => {
    document.dispatchEvent(new CustomEvent(eventName, {
        detail: params
    }));
  };
  getExtension = (filename) => {
    return filename.split('.').pop();
  }
  scrollDirection = () => {
    let scrollPosition = document.documentElement.scrollTop;
    let direction = "DOWN";
    if (scrollPosition > this.scrollLastPosition) direction = "DOWN";
    else if (scrollPosition < this.scrollLastPosition) direction = "UP";
    this.scrollLastPosition = scrollPosition <= 0 ? 0 : scrollPosition;
    return direction;
  }
  /**
   * GET FOCUSABLE ELEMENTS
   * 
   * Returns all focusable elements within a target element
   * 
   * @param {HTMLElement} target 
   * @returns {NodeListOf<HTMLElement>}
   */
  getFocusableElements = (target) => {
    return target.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])');
  }
  /**
   * RENAME ATTRIBUTE
   * 
   * Renames an attribute of an element
   * 
   * @param {HTMLElement} element 
   * @param {string} oldAttr 
   * @param {string} newAttr 
   */
  renameAttribute = (element, oldAttr, newAttr) => {
    if (element.hasAttribute(oldAttr)) {
      element.setAttribute(newAttr, element.getAttribute(oldAttr));
      element.removeAttribute(oldAttr);
    }
  };
  getUrlParameter = (sParam) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(sParam) || true;
  };
  isUserLogged = () => {
    return document.body.classList.contains('user-logged');
  }   

  serializeData (data) {
    let obj = {};
    for (let [key, value] of data) {
      if (obj[key] !== undefined) {
        if (!Array.isArray(obj[key])) {
          obj[key] = [obj[key]];
        }
        obj[key].push(value);
      } else {
        obj[key] = value;
      }
    }
    return obj;
  }
  serialize = (form) => {
    let data = new FormData(form);
    return this.serializeData(data);
  }

  imgGenerator = async (elem) => {
    let img = document.createElement('img');
    for (let attr of elem.attributes) {
      if (attr.name.startsWith('data-js-img-')) {
        let imgAttr = attr.name.replace('data-js-img-', '');
        img.setAttribute(imgAttr, attr.value);
      }
    }
    elem.replaceWith(img);
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
}
export const utils = new Utils(); 
document.addEventListener("DOMContentLoaded", function() {
  utils.init();
});