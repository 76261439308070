import { object } from "../helpers/object";
import { debug } from "../helpers/debug";
import { utils } from "../helpers/utils";

/*------------------------------------*\
  #MASK

  Gestion de fake mask

  [data-js-trigger="mask"]
\*------------------------------------*/
class Mask {


  constructor(module){
    this.MODULE = module;
    this.DOM_MODULE = object.toArray(document.querySelectorAll(this.MODULE));
    this.DOM_mask_state = document.querySelector('[data-js-mask]');
    this.LOCK = false;
  }
  /**
   * INITIALISATION
   * @returns 
   */
  init = () => {
    if (!this.DOM_MODULE.length) return;
    // DEFAULT EVENT
    document.addEventListener('click', this.clickHandler);
    // KEY EVENTS
    document.addEventListener('keyup', this.keyHandler);

    if(debug.DEBUG) console.log('components/mask.js');
  };

  /**
   * CLICK HANDLER
   * 
   * Gestionnaire des event de la barre de navigation
   * @param {event} event 
   * @returns 
   */
  clickHandler = (event) => {
    if (event.target.matches('[data-js-trigger="mask"]')) {
      this.display = false;
    }
  }
  /**
   * CLOSE ON ESC KEY
   * 
   * Fermeture des sub-nav au clic sur la touche echap
   * @param {event} event 
   */
  keyHandler = (event) => {
    if (utils.isEscapKey(event.key || event.keyCode)) {
      this.display = false;
    }
  };
  set lock (state) {this.LOCK = state; }
  get lock () { return this.LOCK; }
  /**
   * SET DISPLAY
   */
  set display (state) {
    if (this.lock) return;
    this.DOM_mask_state.setAttribute('data-js-mask', state);
  }
  /**
   * GET DISPLAY
   */
  get display () {
    return this.DOM_mask_state.getAttribute('data-js-mask');
  }
}
export const mask = new Mask('[data-js-component="mask"]'); 