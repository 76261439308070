import { debug } from "./debug";

/*------------------------------------*\
  #OBJECTS

  Class gestion des objects
\*------------------------------------*/
class Object {
  constructor(){}

  /*------------------------------------*\
    #INITIALISATION
  \*------------------------------------*/
  init = () => {
    if(debug.DEBUG) console.log('helpers/object.js');
  }
  /*------------------------------------*\
    #TO ARRAY

    convertion en array 
  \*------------------------------------*/
  toArray = (array) => {
    return Array.prototype.slice.call(array);
  }
}
export const object = new Object(); 
document.addEventListener("DOMContentLoaded", function() {
  object.init();
});